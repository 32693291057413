.mb-0 {
  margin-bottom: 0 !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.no-pointer-events > * {
  pointer-events: none;
}

.make-scrollable {
  height: 150px;
  overflow-y: scroll;
}

.logo-color {
  background-color: #4E5C6A;
  display: inline-flex;
  height: 55px;
  margin: auto;
  padding: 0 64px;
}

.style-role-block {
  padding: 5px;
  text-align: right;
  height: 64px;
}