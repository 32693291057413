.pop-up-card-preview {
  //background-image: linear-gradient(to top, #46778c, #46778c);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  position: relative;
  &::after {
    content: "";
    background: linear-gradient(to top, #46778c, #46778c66);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }

  .ant-col {
    z-index: 1;
  }

  .pop-up-card-preview-col {
    text-align: center;
    h1 {
      text-align: right;
      color: #fff;
      font-size: 84px;
      line-height: 1;
      margin-bottom: 0;
    }

    h2,
    p {
      font-size: 38px;
      line-height: normal;
      letter-spacing: 0.46px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 10px;
    }

    img {
      width: 450px;
      height: auto;
      margin-bottom: 30px;
    }
  }

  .pop-up-card-preview-col-bottom {
    display: flex;
    justify-content: space-between;

    > div {
      font-size: 32px;
      line-height: normal;
      letter-spacing: 0.51px;
      font-weight: bold;
      color: #fff;
    }
  }

  &.popup-preview {
    padding: 30px 70px;
    .list-card-preview {
      position: relative;
      padding-left: 20px;
      ul {
        padding: 30px 0;
        list-style: none;
        font-size: 28px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.38px;
        margin: 0;
        color: #fff;
        li {
          margin-bottom: 20px;
        }
      }

      img {
        width: 272px;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .card-preview-bottom {
      font-size: 24px;
      font-weight: normal;
      line-height: 1.17;
      letter-spacing: 0.29px;
      color: #fff;
      text-align: center;
      padding: 0 20px;
      div {
        margin-bottom: 24px;
      }
    }

    .card-bottom {
      font-size: 32px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0.38px;
      color: #fff;
      text-align: right;
    }
  }
}

.validity {
  margin-top: 20px;
}
