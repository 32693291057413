@import "~antd/dist/antd.less";
@import "./less/login.less";
@import "./less/sidebar.less";
@import "./less/cardpreview.less";
@import "./less/pushnotification.less";
@import "./less/utilities.less";

@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
'Noto Color Emoji';
// site logo
img.footer-logo {
  margin: 0 10px;
  height: 50px;
  width: auto;
}

.ant-table-wrapper {
  margin: 0.5em 0;
}

.ant-upload.ant-upload-drag {
  height: 300px;
  .ant-upload-btn {
    padding: 16px;
  }
}

.select-all-scroll {
  max-height: 125px;
  padding: 10px 0;
  overflow: auto;
  height: auto;
  border-top: 1px solid;
}

.select-all-checkbox {
  height: 130px;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

@primary-color: #ff4d4f;