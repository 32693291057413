.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.loginimg_text {
  margin-top: 60px;
  img {
    width: 100%;
  }

  h3,
  h1 {
    text-align: center;
  }

  h1 {
    margin: 10px 0;
    font-weight: bold;
  }

  h3 {
    margin-bottom: 40px;
  }
}
